$(document).ready(function () {
  let passwordMode = true;

  $('.sso-switcher').on('click', function (e) {
    e.preventDefault();

    passwordMode = !passwordMode;
    $('#top-errors').text('');

    if (passwordMode) {
      $('.hidden-for-sso').removeClass('hidden');
      $('.hidden-for-password').addClass('hidden');
    }
    else {
      $('.hidden-for-sso').addClass('hidden');
      $('.hidden-for-password').removeClass('hidden');
    }
  });

  $('#sign_in_form').on('click', '#sign_in_button', function (e) {
    e.preventDefault();
    login();
    return false;
  });

  function login() {
    const userType = 'lawyer';
    const spinner = $('.spinner');
    const formButtons = $('.form-control');
    const signInButton = $('#sign_in_button');

    const data = { remote: true, commit: 'Sign in', utf8: '✓', browserTime: new Date().toString(), mode: (passwordMode ? 'password' : 'sso') };
    data[userType] = { password: $('#password').val(), email: $('#login_email').val() };

    spinner.removeClass('display-none');
    formButtons.attr('disabled', true);
    signInButton.attr('disabled', true);

    const enableUi = function () {
      spinner.addClass('display-none');
      formButtons.attr('disabled', false);
      signInButton.attr('disabled', false);
    };

    const url = '/' + userType + 's/sign_in.json';
    $.ajax(url, {
      data: data,
      type: 'POST',
      beforeSend: function (jqXHR) {
        jqXHR.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }
    })
    .done(function (response) {
      if (response.success) {
        window.location = response.redirect;
      }
      else {
        $('#top-errors').text(response.error);
        enableUi();
        if (response.error === 'You have to confirm your account before continuing.') {
          $('#confirmation-link').removeClass('hidden');
        }
      }
    })
    .fail(function (response) {
      const error = $.parseJSON(response.responseText).error;
      $('#top-errors').text(error);
      enableUi();
      if (error === 'You have to confirm your account before continuing.') {
        $('#confirmation-link').removeClass('hidden');
      }
    });
  }
});
